<footer class="footer-peugeot">
    <div class="container">
        <div class="row pt-5">
            <div class="col-md-4">
                <h6><img class="img-fluid px-3" src="assets/images/logo_paris.png" alt=""></h6>
                <div class="ml-md-5">
                    <h4><small>En</small> San Luis</h4>
                    <address>
                        Av. Santos Ortiz 1228<br />
                        San Luis - Argentina<br />
                        Tel. 266 4 438343<br />
                        <a class="telef-wp"
                            href="https://api.whatsapp.com/send?phone=5492664303957&text=Hola!%20Tengo%20una%20consulta!">
                            Cel. 266 4 303957 <i class="fab fa-whatsapp txt-mas"></i></a><br />
                    </address>
                    <h4><small>En</small> Villa Mercedes</h4>
                    <address>
                        Av. Mitre 888<br />
                        San Luis - Argentina<br />
                        Tel. 2657 427700<br />
                        <a class="telef-wp"
                            href="https://api.whatsapp.com/send?phone=5492664002069&text=Hola!%20Tengo%20una%20consulta!">
                            Cel. 266 4002069 <i class="fab fa-whatsapp txt-mas"></i></a><br />
                    </address>
                </div>
            </div>
            <div class="col-md-2 d-none d-md-block">
                <h6>GRUPO PARIS</h6>
                <p><a target="_blank" href="https://peugeot.parisautos.com.ar/" class="">
                        Peugeot</a></p>
                <p><a target="_blank" href="https://citroen.parisautos.com.ar/" class="">
                        Citroën</a></p>
                <p><a target="_blank" href="https://usados.parisautos.com.ar/" class="">
                        Usados</a></p>
                <p><a target="_blank" href="https://postventa.parisautos.com.ar/" class="">
                        Postventa</a></p>
                <p><a target="_blank" href="https://parismotos.com.ar/" class="">
                        Motos</a></p>
            </div>
            <div class="col-md-2 d-none d-md-block">
                <h6>SECCIONES</h6>
                <p><a href="#" class=""> Empresas</a></p>
                <p><a href="#" class=""> Plan de ahorro</a></p>
            </div>
            <div class="col-md-4">
                <h6>CONTACTO</h6>

                <form method="post" action="send-mail2.php">
                    <div class="form-group">
                        <input type="text" name="nombre" class="form-control" id="nombre" aria-describedby="emailHelp"
                            placeholder="Nombre" autocomplete="off" />
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" id="tel" name="tel" placeholder="Celular"
                            autocomplete="off" />
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" id="message" name="message" rows="3"
                            placeholder="Escriba su consulta aca."></textarea>
                    </div>
                    <div class="text-center">
                        <button type="submit" class="btn btn-primary-2 btm-xs rounded-0">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="footer-copyright text-center py-3">
        © 2021 Copyright: <a href="">PARIS AUTOS S.A.</a>
    </div>
</footer>