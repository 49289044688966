<nav class="navbar navbar-expand-md navbar-dark bg-navbar-peugeot d-flex align-items-stretch py-0">
    <button class="navbar-toggler navbar-toggler-right" type="button" aria-controls="appNavigation"
        aria-expanded="!isCollapsed" aria-label="Toggle navigation" (click)="toggleMenu()">
        <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand mx-auto" href="#">
        <img src="assets/images/logo_paris.png" height="80" class="d-inline-block align-top" alt="" loading="lazy">
    </a>
    <div class="collapse navbar-collapse flex-column" id="appNavigation" [ngbCollapse]="isCollapsed">
        <!-- <div class="navbar-nav ml-auto"> -->
        <ul class="navbar-nav navbar-secondary mt-auto ml-auto">
            <li class="nav-item">
                <span class="nav-info">San Luis: Av Santos Ortiz 1228 - </span>
            </li>
            <li class="nav-item">
                <span class="nav-info">V. Mercedes: Av Mitre 888 - </span>
            </li>
            <li class="nav-item">
                <span class="nav-info">Cel: 266-4303957</span>
            </li>
        </ul>
        <ul class="navbar-nav navbar-primary mt-auto ml-auto d-flex">
            <li class="nav-item flex-fill">
                <a class="nav-link" href="#home">HOME</a>
            </li>
            <li class="nav-item flex-fill">
                <a class="nav-link" href="#modelos">MODELOS</a>
            </li>
            <li class="nav-item flex-fill">
                <a class="nav-link" href="#canales">VENTAS</a>
            </li>
            <li class="nav-item flex-fill">
                <a class="nav-link" href="#contacto">CONTACTO</a>
            </li>
        </ul>
    </div>
</nav>
